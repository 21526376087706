import { UUID } from "io-ts-types/UUID";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { Slug } from "@every.org/common/src/codecs/nonprofit";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  getFundraiserRouteSpec,
  getFundraiserRaisedRouteSpec,
  getAdminFundraisersRouteSpec,
  getNonprofitFundraisersCachedRouteSpec,
  getUserFundraisersRouteSpec,
} from "@every.org/common/src/routes/fundraiser";
import { TakeInt, SkipInt } from "@every.org/common/src/routes/index";

import { addNonprofits } from "src/context/NonprofitsContext";
import { addTags } from "src/context/TagContext/actions";
import { addUsers } from "src/context/UsersContext/actions";
import { isNotFoundApiError } from "src/errors/ApiError";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

export enum FundraiserFetchStatus {
  FUNDRAISER_NOT_FOUND = "FUNDRAISER_NOT_FOUND",
  FETCHING_FUNDRAISER = "FETCHING_FUNDRAISER",
}

export enum FundraiserRaisedFetchStatus {
  FUNDRAISER_RAISED_NOT_FOUND = "FUNDRAISER_RAISED_NOT_FOUND",
  FETCHING_FUNDRAISER_RAISED = "FETCHING_FUNDRAISER_RAISED",
}

export enum NonprofitFundraisersFetchStatus {
  NONPROFIT_FUNDRAISERS_NOT_FOUND = "NONPROFIT_FUNDRAISERS_NOT_FOUND",
  FETCHING_NONPROFIT_FUNDRAISERS = "FETCHING_NONPROFIT_FUNDRAISERS",
}

export enum UserFundraisersFetchStatus {
  USER_FUNDRAISERS_NOT_FOUND = "USER_FUNDRAISERS_NOT_FOUND",
  FETCHING_USER_FUNDRAISERS = "FETCHING_USER_FUNDRAISERS",
}

export function fundraiserOrUndefined(
  funraiser: FundraiserResponse | FundraiserFetchStatus
) {
  if (
    funraiser === FundraiserFetchStatus.FETCHING_FUNDRAISER ||
    funraiser === FundraiserFetchStatus.FUNDRAISER_NOT_FOUND
  ) {
    return undefined;
  }
  return funraiser;
}

export async function fetchFundraiser(
  fundraiserIdentifier: Slug | UUID,
  nonprofitIdentifier: Slug | UUID,
  skipCache?: boolean
) {
  try {
    const [fundraiserResponse, raisedDataResponse] = await Promise.all([
      queryApi(getFundraiserRouteSpec, {
        routeTokens: { fundraiserIdentifier, nonprofitIdentifier },
        body: {},
        queryParams: skipCache ? { skipCache: true } : {},
      }),
      queryApi(getFundraiserRaisedRouteSpec, {
        routeTokens: { fundraiserIdentifier, nonprofitIdentifier },
        body: {},
        queryParams: skipCache ? { skipCache: true } : {},
      }),
    ]);
    addNonprofits(fundraiserResponse.nonprofits);
    addUsers(fundraiserResponse.users);
    addTags(fundraiserResponse.tags);
    return { ...fundraiserResponse.fundraiser, raisedData: raisedDataResponse };
  } catch (e) {
    if (isNotFoundApiError(e)) {
      return FundraiserFetchStatus.FUNDRAISER_NOT_FOUND;
    }
    logger.error({
      message: "An error occurred fetching a fundraiser.",
      error: e,
    });
    throw new Error("Something went wrong, please try again.");
  }
}

export const fetchFundraiserRaised = async (
  fundraiserId: UUID,
  nonprofitId: UUID
) => {
  try {
    const response = await queryApi(getFundraiserRaisedRouteSpec, {
      routeTokens: {
        fundraiserIdentifier: fundraiserId,
        nonprofitIdentifier: nonprofitId,
      },
      body: {},
      queryParams: {},
    });

    return response;
  } catch (e) {
    if (isNotFoundApiError(e)) {
      return FundraiserRaisedFetchStatus.FUNDRAISER_RAISED_NOT_FOUND;
    }
    logger.error({
      message: "An error occurred fetching a raised fundraiser.",
      error: e,
    });
    throw new Error("Something went wrong, please try again.");
  }
};

export const fetchNonprofitFundraisers = async (
  nonprofitId: UUID,
  take: number,
  publicFeed?: boolean | undefined
) => {
  try {
    const route =
      publicFeed !== false
        ? getNonprofitFundraisersCachedRouteSpec
        : getAdminFundraisersRouteSpec;
    const response = await queryApi(route, {
      routeTokens: {
        id: nonprofitId,
      },
      body: {},
      queryParams: removeUndefinedValues({
        take: take as TakeInt,
        skip: 0 as SkipInt,
        publicFeed,
      }),
    });

    return response;
  } catch (e) {
    if (isNotFoundApiError(e)) {
      return NonprofitFundraisersFetchStatus.NONPROFIT_FUNDRAISERS_NOT_FOUND;
    }
    logger.error({
      message: "An error occurred fetching a nonprofit fundraisers.",
      error: e,
    });
    throw new Error("Something went wrong, please try again.");
  }
};

export const fetchUserFundraisers = async (
  userId: UUID,
  take: number,
  publicFeed = false
) => {
  try {
    const response = await queryApi(getUserFundraisersRouteSpec, {
      routeTokens: {
        id: userId,
      },
      body: {},
      queryParams: {
        take: take as TakeInt,
        skip: 0 as SkipInt,
        publicFeed,
      },
    });

    return response;
  } catch (e) {
    if (isNotFoundApiError(e)) {
      return UserFundraisersFetchStatus.USER_FUNDRAISERS_NOT_FOUND;
    }
    logger.error({
      message: "An error occurred fetching a user fundraisers.",
      error: e,
    });
    throw new Error("Something went wrong, please try again.");
  }
};
