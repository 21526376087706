const STOCK_BROKERAGE = {
  institution: "AlpacaSecurities LLC",
  dtc: "3021",
  account: "456981656",
  title: "Every.org",
};

const MUTUAL_FUND_BROKERAGE = {
  institution: "First Republic Securities Company LLC",
  dtc: "0443 (Clearing through Pershing LLC)",
  account: "33L257954",
  title: "Every.org",
};

/**
 * Use Alpaca by default, for all Stocks and ETFs.
 * Alpaca does not support mutual funds where the
 * ticker symbol ends in X, so then use FRB.
 */
export function getBrokerageDtcInfo(tickerSymbol?: string) {
  if (tickerSymbol?.toUpperCase()?.endsWith("X")) {
    return MUTUAL_FUND_BROKERAGE;
  }
  return STOCK_BROKERAGE;
}
